import { Message, SnackbarState } from './Types'
import { AppState } from '../../Types'

export const getSelf = (storeState: AppState): SnackbarState => storeState.snackbar

export const getQueue = (state: SnackbarState) => state.queue

export const isOpen = (state: SnackbarState): boolean => state.isOpen

export const getMessage = (state: SnackbarState): Message | undefined => state.messageInfo
