/* OPERATIONS = REDUX THUNKS / SAGAS...
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator
Complex operations involve returning a thunk that dispatches multiple actions in a certain order
*/

import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import * as actions from './Actions'
import * as snackbarcallSelectors from './Selectors'
import { Options, Variant } from './Types'
import { AppState } from '@src/store/Types'

/**
 * Opens new snackbar
 */
export const open = (
  message: string,
  variant: Variant,
  options?: Partial<Options>,
): ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
  // Pushes message to query
  dispatch(actions.push(message, variant, options))

  if (!process.browser) {
    return
  }

  // ... and continues to query processing
  const snackbar = snackbarcallSelectors.getSelf(getState())
  // When any message is already opened
  if (snackbarcallSelectors.isOpen(snackbar)) {
    // immediately begin dismissing current message
    // to start showing new one
    dispatch(actions.close())
  } else {
    dispatch(processQueue())
  }
}

/**
 * Proceeds to next snackbar when new snack was pushed to queue or old one was closed
 */
export const processQueue = (): ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
  const snackbar = snackbarcallSelectors.getSelf(getState())
  const queue = snackbarcallSelectors.getQueue(snackbar)

  if (queue.length > 0) {
    dispatch(actions.shift())
  }
}

/**
 * Closes currently display snackbar
 */
export const close = actions.close
