import { now } from '@lib/withTimeSync'

import { Options, SnackbarState } from './Types'
import { snackbarActionTypes, SnackbarAction, PushAction } from './Actions'

const initialState: SnackbarState = {
  isOpen: false,
  queue: [],
  messageInfo: undefined,
}

const defaultOptions: Options = {
  autoHideDuration: 4000,
  verticalOrigin: 'top',
}

const close = (state: SnackbarState): SnackbarState => {
  return {
    ...state,
    isOpen: false,
  }
}

const push = (state: SnackbarState, action: PushAction): SnackbarState => {
  return {
    ...state,
    queue: [
      ...state.queue,
      {
        message: action.payload.message,
        options: {
          ...defaultOptions,
          ...action.payload.options,
        },
        variant: action.payload.variant,
        key: new Date(now()).getTime(),
      },
    ],
  }
}

const shift = (state: SnackbarState): SnackbarState => {
  if (!state.queue.length) {
    return state
  }

  const messageInfo = state.queue[0]

  return {
    ...state,
    isOpen: true,
    messageInfo,
    queue: [...state.queue.slice(1)],
  }
}

const reducer = (state = initialState, action: SnackbarAction) => {
  switch (action.type) {
    case snackbarActionTypes.CLOSE:
      return close(state)
    case snackbarActionTypes.PUSH:
      return push(state, action as PushAction)
    case snackbarActionTypes.SHIFT:
      return shift(state)
    default:
      return state
  }
}

export default reducer
