import { detect, BrowserInfo, BotInfo, NodeInfo } from 'detect-browser'
import isSupported from 'twilio-video/lib/util/support'

let detectedBrowser = undefined

/**
 * Browser name / version / OS detection. Pass userAgent parameter for server-side detection
 */
export const detectBrowser = (userAgent?: string): BrowserInfo | BotInfo | NodeInfo => {
  // Cache in browser only. Sharing result on server would be bad
  const useCache = process.browser && !userAgent
  detectedBrowser = !useCache || detectedBrowser === undefined ? detect(userAgent) : detectedBrowser

  return detectedBrowser
}

export const isStandalone = () => {
  return Boolean(window.matchMedia('(display-mode: standalone)').matches)
}

export const isSafari = (userAgent: string = navigator.userAgent) => {
  return userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1
}

export const isSeznamMobile = (userAgent: string = navigator.userAgent) => {
  return userAgent.indexOf('SznProhlizec') !== -1 && userAgent.indexOf('Mobile') !== -1
}

export const isCypress = (userAgent: string = navigator.userAgent) => {
  return userAgent.indexOf('Cypress') !== -1 && userAgent.indexOf('Chrome') !== -1
}

/**
 * Twilio video support detection. Mind that detection works in browser only.
 * In Cypress we pretend that call is supported
 */
export const isVideoCallSupported = (): boolean => Boolean((isSupported() && !isSeznamMobile()) || isCypress())

// Example user agents of Seznam prohlizec
// Android - not working
// Mozilla/5.0 (Linux; Android 9; POCOPHONE F1 Build/PKQ1.180729.001) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/80.0.3987.99 Mobile Safari/537.36 SznProhlizec/7.1.2a
// Iphone - not working
// Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_5 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Safari/605.1.15 SznProhlizec/7.3i
// Mac OS - works
// Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.75 Safari/537.36 SznProhlizec/6.2.1
// Windows - works
// Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.130 Safari/537.36 SznProhlizec/6.2.1
