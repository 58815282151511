import { Options, Variant } from './Types'

export const snackbarActionTypes = {
  CLOSE: 'snackBars/close',
  PUSH: 'snackBars/push',
  SHIFT: 'snackBars/shift',
}

export type CloseAction = {
  type: typeof snackbarActionTypes.CLOSE
}

export type PushAction = {
  type: typeof snackbarActionTypes.PUSH
  payload: {
    message: string
    variant: Variant
    options?: Partial<Options>
  }
}

export type ShiftAction = {
  type: typeof snackbarActionTypes.SHIFT
}

export const close = (): CloseAction => ({
  type: snackbarActionTypes.CLOSE,
})

export const push = (message: string, variant: Variant, options?: Partial<Options>): SnackbarAction => ({
  type: snackbarActionTypes.PUSH,
  payload: {
    message,
    variant,
    options,
  },
})

export const shift = (): ShiftAction => ({
  type: snackbarActionTypes.SHIFT,
})

export type SnackbarAction = CloseAction | PushAction | ShiftAction
