import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar'
import { HTMLAttributes, CSSProperties } from 'react'

import { getUserImageUrl } from '@helpers/urls'

type Props = AvatarProps & {
  size?: number | string
  marginRight?: number | string
  localSrc?: boolean
  style?: CSSProperties
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    background: '#FFFAEF',
    color: palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 900,
  },
}))

const Avatar = ({ className, size, src, marginRight, alt, style, localSrc = false, ...props }: Props) => {
  const classes = useStyles()

  return (
    <MuiAvatar
      className={cx(classes.root, className)}
      style={{ width: size, height: size, marginRight, cursor: props.onClick ? 'pointer' : 'default', ...style }}
      src={localSrc ? src : getUserImageUrl(src)}
      alt={alt || 'User'}
      imgProps={
        {
          'data-testid': 'avatar',
        } as HTMLAttributes<HTMLImageElement>
      }
      {...props}
    />
  )
}

export default Avatar
